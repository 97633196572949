@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;1,400;1,500&display=swap");

// 1. Your custom variables and variable overwrites.
$global-font-family: "EB Garamond", serif;
$global-primary-background: #b62025;
$internal-divider-icon-image: "../assets/images/cross.svg";

// 2. Import default variables and available mixins.
@import "~uikit/src/scss/variables-theme.scss";
@import "~uikit/src/scss/mixins-theme.scss";

// 4. Import UIkit.
@import "~uikit/src/scss/uikit-theme.scss";

.primary-color {
  color: $global-primary-background;
}

.uk-divider-icon {
  background-size: contain;
}

::selection,
::-moz-selection {
  background: rgba(182, 32, 37, 0.6);
}

html,
body {
  scroll-behavior: smooth;
}

.able {
  margin: 0;
}

.able-transcript-area {
  border: none;
  padding-bottom: 10px;
  user-select: none;
  height: 580px;
  @media (max-width: 1600px) {
    height: 460px;
  }
}

.able-transcript {
  max-height: 590px;
  height: 100%;
  @media (max-width: 1600px) {
    max-height: 410px;
  }
}

.able-captions,
#driver-popover-item * {
  font-family: "EB Garamond", serif !important;
}
.able-prefCaptionsFont {
  display: none !important;
}

div.able-chapters-div ul {
  list-style-type: lower-greek;
  padding-left: 30px;
}

.able-transcript span:active,
.able-transcript span:focus,
.able-transcript span:hover {
  color: #fff;
  background: $global-primary-background;
}

div.able-chapters-div li.able-current-chapter,
button.able-search-results-time:active,
button.able-search-results-time:focus,
button.able-search-results-time:hover {
  background-color: $global-primary-background !important;
}

button {
  font-family: "EB Garamond", serif !important;
}

div.able-chapters-div {
  button {
    &:hover {
      color: inherit !important;
      cursor: pointer;
    }
  }
}

.able-seekbar-played {
  background-color: $global-primary-background;
}

.episodes-list {
  li {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    br {
      display: none;
    }
  }
}

.active-episode {
  color: #fff;
  background-color: $global-primary-background !important;
  a {
    color: #fff !important;
  }
}

.uk-modal {
  z-index: 10000;
}

#result-container {
  max-height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 50px;
  box-sizing: border-box;
}

.timed-cue {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.js-search_single {
  top: 20px;
  position: sticky;
}

#search_results-container {
  > ul {
    @media (min-width: 640px) {
      white-space: nowrap;
    }
    > li {
      margin: 5px 0;
      &:nth-last-of-type(odd) {
        background-color: #f3f3f3;
      }
    }
  }
  > p {
    background: rgba(255, 255, 255, 0.94);
    margin: 0;
    position: sticky;
    &:first-child {
      top: 0px;
      padding: 10px 0;
    }
    &:nth-child(2) {
      padding: 10px 0;
      top: 40px;
    }
  }
}

@media (max-width: 640px) {
  .flex-column-ats {
    flex-direction: column;
  }

  .margin-top-ats {
    margin-top: 20px !important;
  }

  .no-margin-left-ats {
    margin-left: 0 !important;
  }

  .episode-nav {
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    justify-content: flex-start;

    li {
      padding-left: 0 !important;
    }
  }

  .js-episode-container {
    h2 {
      font-size: 1.2rem;
    }
  }

  .ytsub {
    margin-top: 1rem;
    margin-left: 1rem !important;
  }

  .uk-navbar-toggle {
    min-height: auto;
  }
}

#chapters-container nav {
  white-space: nowrap;
  button {
    overflow: hidden;
    padding-right: 20px;
    text-overflow: ellipsis;
  }
}

.able-transcript div.able-transcript-desc {
  padding: 0.3em;
  border: none;
  background: none;
}

.uk-modal-dialog {
  max-height: 100vh;
}
